<template>
	<div class="">
		<el-card class="box-card">
			<el-form :model="ruleForm"  ref="ruleForm" label-width="130px" class="demo-ruleForm">
				<el-form-item label="反馈类型:" prop="type" :rules="[{ required: true, message: '请选择反馈类型' }]">
					<el-select v-model="ruleForm.type" placeholder="请选择反馈类型" style="width: 500px;">
						<el-option
						v-for="item in gzoptions"
						:key="item.value"
						:label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="反馈内容：" prop="content" :rules="[{ required: true, message: '请输入您的意见反馈' }]">
					<el-input
						type="textarea"
						:autosize="{ minRows: 8, maxRows: 10 }"
						show-word-limit
						placeholder="请输入您的意见反馈"
						v-model="ruleForm.content"
						style="width: 500px;"
					></el-input>
				</el-form-item>
				<el-form-item label="添加附件：" prop="imgurl">
					<el-upload
						action="https://freemen.work/user/file/upload/"
						list-type="picture-card"
						:on-remove="handleRemove"
						:on-success="handleSuccess">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="updialogVisible">
						<img width="100%" :src="'https://freemen.work/user/file/view/'+imgShow" alt="" />
					</el-dialog>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
export default {
	data() {
		return {
			ruleForm: {
				type: '',
				content:'',
				imgurl:''
			},
			gzoptions: [],
			updialogVisible: false,
			imgShow:''
		};
	},
	created() {
		this.getTypeOption()
	},
	methods: {
		getTypeOption(){
			this.$api.getdict('get',{code:'feedback_type'}).then(res=>{
				let arr = []
				res.data.map(item=>{
					let obj = {
						value:item.id,
						label:item.name
					}
					arr.push(obj)
				})
				this.gzoptions = arr
			})
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let config = {
						feedbackType:this.ruleForm.type,
						content:this.ruleForm.content,
						images:this.ruleForm.imgurl,
					}
					this.$api.advise('post',config).then(()=>{
						this.$message({
							message:'提交成功',
							type:'success'
						})
					})
				} else {
					return false;
				}
			});
		},
		handleRemove(file, fileList) {
			// console.log(file, fileList);
			let arr = []
			fileList.map(item=>{
				arr.push(item.response.data)
			})
			this.ruleForm.imgurl = arr.join(',')
		},
		handleSuccess(response, file, fileList){
			// console.log(file,fileList)
			let arr = []
			fileList.map(item=>{
				arr.push(item.response.data)
			})
			this.ruleForm.imgurl = arr.join(',')
		},
	}
};
</script>

<style lang="less" scoped>
/deep/.el-card {
	border-radius: 0px;
	border: none;
	box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
	padding: 80px 30px 90px 30px;
	display: flex;
	align-items: center;
	justify-content: center;

}


.demo-ruleForm{
	width: 700px;
	/deep/ .el-input__inner, /deep/ .el-textarea__inner{
		border-radius: 0px;
	}
	/deep/ .el-button {
		border-radius: 0px;
		width: 500px;
		margin-top: 50px;
		background: #00bcff;
		border: none;
	}
	/deep/ .el-upload--picture-card {
		width: 80px;
		height: 80px;
		border-radius: 0px;
	}
	/deep/ .el-upload {
		width: 80px;
		height: 80px;
		line-height: 80px;
		border-radius: 0px;
	}
	/deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 80px;
		height: 80px;
		line-height: 80px;
		border-radius: 0px;
	}
	/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
		width: 80px;
		height: 80px;
		line-height: 80px;
		border-radius: 0px;
	}
	/deep/ .avatar {
		width: 80px;
		height: 80px;
		border-radius: 0px;
	}
	.imgts {
		color: #999999;
	}
}






</style>
